import { Routes } from '@angular/router';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/outreach',
    pathMatch: 'full',
  },
  {
    path: 'help',
    loadComponent: () =>
      import('./pages/_core/help/help.page').then((m) => m.HelpPage),
  },
  {
    path: 'share',
    loadComponent: () =>
      import('./pages/_core/share/share.page').then((m) => m.SharePage),
  },
  {
    path: 'check-for-updates',
    loadComponent: () =>
      import('./pages/_core/check-for-updates/check-for-updates.page').then(
        (m) => m.CheckForUpdatesPage
      ),
  },
  {
    path: 'help-forgot-pwd',
    loadComponent: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.page').then(
        (m) => m.HelpForgotPwdPage
      ),
  },
  {
    path: 'feedback-modal',
    loadComponent: () =>
      import('./pages/_core/feedback-modal/feedback-modal.page').then(
        (m) => m.FeedbackModalPage
      ),
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService],
    loadComponent: () =>
      import('./pages/_core/my-account/my-account.page').then(
        (m) => m.MyAccountPage
      ),
  },
  {
    path: 'language-modal',
    loadComponent: () =>
      import('./pages/_core/language-modal/language-modal.page').then(
        (m) => m.LanguageModalPage
      ),
  },
  {
    path: 'language-popover',
    loadComponent: () =>
      import('./pages/_core/language-popover/language-popover.page').then(
        (m) => m.LanguagePopoverPage
      ),
  },
  {
    path: 'language-translator',
    loadComponent: () =>
      import('./pages/_core/language-translator/language-translator.page').then(
        (m) => m.LanguageTranslatorPage
      ),
  },
  {
    path: 'outreach',
    // canActivate: [LoginRouteGuardService],
    loadComponent: () =>
      import('./pages/outreach/outreach.page').then((m) => m.OutreachPage),
  },
  {
    path: 'response',
    //  canActivate: [LoginRouteGuardService],
    loadComponent: () =>
      import('./pages/response/response.page').then((m) => m.ResponsePage),
  },
  {
    path: 'response/:specialty',
    // canActivate: [LoginRouteGuardService],
    loadComponent: () =>
      import('./pages/response/response.page').then((m) => m.ResponsePage),
  },
  {
    path: 'response/:specialty/:status',
    // canActivate: [LoginRouteGuardService],
    loadComponent: () =>
      import('./pages/response/response.page').then((m) => m.ResponsePage),
  },
  {
    path: 'response/:specialty/:status/:consultId',
    // canActivate: [LoginRouteGuardService],
    loadComponent: () =>
      import('./pages/response/response.page').then((m) => m.ResponsePage),
  },
  {
    path: 'launch',
    loadComponent: () =>
      import('./pages/launch/launch.page').then((m) => m.LaunchPage),
  },
  {
    path: 'launch/:target',
    loadComponent: () =>
      import('./pages/launch/launch.page').then((m) => m.LaunchPage),
  },
  {
    path: 'redirect',
    loadComponent: () =>
      import('./pages/redirect/redirect.page').then((m) => m.RedirectPage),
  },
  {
    path: 'redirect/:target',
    loadComponent: () =>
      import('./pages/redirect/redirect.page').then((m) => m.RedirectPage),
  },
  {
    path: 'linker',
    loadComponent: () =>
      import('./pages/linker/linker.page').then((m) => m.LinkerPage),
  },
  {
    path: 'consult-select',
    loadComponent: () =>
      import('./pages/consult-select/consult-select.page').then(
        (m) => m.ConsultSelectPage
      ),
  },
];
